'use client'

import React from 'react'

import Button from '@/components/ui/Button/Button'
import Dropdown, { TListItem } from '@/components/ui/Dropdown/Dropdown'
import { useDropdown } from '@/components/ui/Dropdown/useDropdown'

import Chevron from '@/assets/icons/arrows/chevron-bottom.svg'
import Login from '@/assets/icons/header/menu/login.svg'
import Register from '@/assets/icons/header/menu/briefcase.svg'
import Marketing from '@/assets/icons/header/menu/megaphone.svg'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

type TServiceProviders = {}

const event_category = 'For Service Providers Menu'

const SERVICE_PROVIDERS_MENU: TListItem[] = [
  {
    name: 'Login as Provider',
    Icon: Login,
    rel: 'noreferrer',
    href: `${process.env.NEXT_PUBLIC_GIS_HOME}/grovvup/user/login`,
    event_label: 'Login',
    event_category,
  },
  {
    name: 'Register as Provider',
    Icon: Register,
    href: `/for-business`,
    event_label: 'Register',
    event_category,
  },
  {
    name: 'Marketing solutions',
    Icon: Marketing,
    href: `/marketing`,
    event_label: 'Marketing',
    event_category,
  },
]

export default function ServiceProviders({}: TServiceProviders) {
  const { isMenuVisible, anchorEl, showMenu, closeMenu } = useDropdown()

  const handleButtonCLick = (e) => {
    showMenu(e)
    trackGAEvent({
      action: 'click',
      category: 'Header Bar',
      label: 'For Service Providers',
      data: `page_url: ${window.location.href}`,
    })
  }

  return (
    <>
      <Button
        text="For service providers"
        Icon={Chevron}
        theme="secondary"
        onClick={(e) => handleButtonCLick(e)}
        active={isMenuVisible}
      />
      <Dropdown
        onClose={closeMenu}
        anchorEl={anchorEl}
        isOpen={isMenuVisible}
        list={SERVICE_PROVIDERS_MENU}
      />
    </>
  )
}
