import React from 'react'

import { useAuth } from '@/context/AuthContext'

import Button from '@/components/Layout/components/Header/components/Button/Button'
import Dropdown, { TListItem } from '@/components/ui/Dropdown/Dropdown'
import { useDropdown } from '@/components/ui/Dropdown/useDropdown'
import UserAvatar from '@/components/Layout/components/Header/components/UserMenu/components/UserAvatar/UserAvatar'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

import IconBlog from '@/assets/icons/header/menu/blog.svg'
import IconAbout from '@/assets/icons/header/menu/about.svg'
import IconUser from '@/assets/icons/header/menu/user.svg'
import IconLogout from '@/assets/icons/header/menu/logout.svg'
import IconLogin from '@/assets/icons/header/menu/login.svg'

export type TUserMenu = {
  styles?: React.CSSProperties
  iconColor?: string
  theme?: 'light'
}

const event_category = 'User Menu'

export default function UserMenu({ styles = {}, iconColor, theme }: TUserMenu) {
  const { user, setIsOpen, logout } = useAuth()
  const { isMenuVisible, anchorEl, showMenu, closeMenu } = useDropdown()

  const handleUserMenuClick = (e) => {
    showMenu(e)

    trackGAEvent({
      action: `click`,
      category: 'Header Bar',
      label: `User Menu`,
      data: `page_url: ${window.location.href}`,
    })
  }

  const onLoginClick = (e) => {
    e?.preventDefault()

    trackGAEvent({
      action: `click`,
      category: event_category,
      label: `Login`,
      data: `page_url: ${window.location.href}`,
    })

    setIsOpen(true)
  }

  const PROFILE_MENU: TListItem[] = [
    user
      ? {
          name: 'Profile',
          Icon: IconUser,
          href: `${process.env.NEXT_PUBLIC_GIS_USER_PORTAL}`,
          event_label: 'Profile',
          event_category,
        }
      : {
          name: 'Login',
          Icon: IconLogin,
          onClick: onLoginClick,
        },
    {
      name: 'Blog',
      Icon: IconBlog,
      href: '/blog',
      rel: 'noreferrer',
      target: '_blank',
      event_label: 'Blog',
      event_category,
    },
    {
      name: 'About Us',
      Icon: IconAbout,
      href: `/about`,
      target: '_blank',
      event_label: 'About',
      event_category,
    },
    ...(user
      ? [
          {
            name: 'Logout',
            Icon: IconLogout,
            onClick: logout,
            event_label: 'Logout',
            event_category,
          },
        ]
      : []),
  ]

  return (
    <>
      <Button
        onClick={(e) => handleUserMenuClick(e)}
        isActive={isMenuVisible}
        styles={user ? { ...styles, backgroundColor: 'var(--color-theme-main)' } : { ...styles }}
        theme={theme}
      >
        <UserAvatar iconColor={iconColor} theme={theme} />
      </Button>
      <Dropdown
        onClose={closeMenu}
        anchorEl={anchorEl}
        isOpen={isMenuVisible}
        list={PROFILE_MENU}
      />
    </>
  )
}
