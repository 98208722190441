'use client'
import React, { useContext } from 'react'
import { ViewportContext } from '@/context/ViewportContext'
import useViewport from '@/hooks/useViewport'

import Logo from './components/Logo/Logo'
import UserMenu from '@/components/Layout/components/Header/components/UserMenu/UserMenu'
import ServiceProviders from './components/ServiceProviders/ServiceProviders'
import AuthModal from '@/components/AuthModal/AuthModal'

// import MobileMenu from '@/components/Layout/components/Header/components/Menu/MobileMenu'
// import NavBar from '@/components/Layout/components/Header/components/Menu/NavBar/NavBar'

import classes from './Header.module.scss'

export default function Header() {
  const { isMobile } = useContext(ViewportContext)
  const viewport = useViewport({ isMobile })

  return (
    <>
      <header
        className="container"
        id="header-main"
        style={{
          backgroundColor: 'var(--color-background)',
          zIndex: 'var(--zindex-cover-mui)',
          position: 'relative',
        }}
      >
        <div className={`${classes.root} ${classes.flex}`}>
          <Logo viewport={viewport} />
          {/*menu is disabled until more pages will be added*/}
          {/*{viewport === 'desktop' && <NavBar />}*/}
          <div className={`${classes.flex} ${classes.nav}`}>
            <ServiceProviders />
            <UserMenu />
            {/*menu is disabled until more pages will be added*/}
            {/*{viewport !== 'desktop' && <MobileMenu />}*/}
          </div>
        </div>
      </header>
      <AuthModal />
    </>
  )
}
