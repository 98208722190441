import React from 'react'
import Link from 'next/link'
import LogoMobile from '@/assets/logo/beakid/beakid--no-text.svg'
import LogoDesktop from '@/assets/logo/beakid/beakid.svg'
import { Viewport } from '../../../../../../types'

type TLogo = {
  viewport: Viewport
}

export default function Logo({ viewport }: TLogo) {
  let Logo = LogoMobile

  if (viewport !== 'mobile') {
    Logo = LogoDesktop
  }

  return (
    <Link href="/" title="BeAKid home">
      <Logo />
    </Link>
  )
}
