import { createContext } from 'react'

export type TViewportContext = {
  isMobile?: boolean
}

export const ViewportContext = createContext<TViewportContext>({
  isMobile: true,
})

// ViewportContext.displayName = 'ViewportContext'
