import { useState, useCallback } from 'react'

export const useDropdown = () => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const showMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (event) {
      setAnchorEl(event.currentTarget)
    }
    setIsMenuVisible((prev) => !prev)
  }, [])

  const closeMenu = () => {
    setAnchorEl(null)
    setIsMenuVisible(false)
  }

  return {
    isMenuVisible,
    anchorEl,
    showMenu,
    closeMenu,
  }
}
