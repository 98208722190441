import { useState, useEffect } from 'react'
import { Viewport } from 'src/types'
import { TViewportContext } from '@/context/ViewportContext'

// const VIEWPORT_MOBILE = 390
const VIEWPORT_TABLET = 834
const VIEWPORT_DESKTOP = 1440

const getViewport = (width: number): Viewport => {
  if (width < VIEWPORT_TABLET) return 'mobile'
  if (width >= VIEWPORT_TABLET && width < VIEWPORT_DESKTOP) return 'tablet'
  return 'desktop'
}

export default function useViewport({ isMobile }: TViewportContext) {
  const [viewport, setViewport] = useState<Viewport>(isMobile ? 'mobile' : 'desktop')

  useEffect(() => {
    const handleResize = () => {
      setViewport(getViewport(window.innerWidth))
    }

    if (typeof window !== 'undefined') {
      setViewport(getViewport(window.innerWidth))
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }

    return undefined
  }, [])

  return viewport
}
