'use client'
import React, { createContext, useState, useEffect, useContext } from 'react'

import { useLazyQuery } from '@apollo/client'
import { CURRENT_USER_QUERY } from '@components/UserMenu/queries'

import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

import { User as TUser } from '../../types'

interface AuthContextType {
  user: TUser | null
  // setUser: (user: TUser | null) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  refetchUser: () => void
  logout: () => void
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  // setUser: () => {},
  isOpen: false,
  setIsOpen: () => {},
  refetchUser: () => {},
  logout: () => {},
})

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<TUser | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const [getUser, { loading, data, refetch }] = useLazyQuery(CURRENT_USER_QUERY)

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    if (data?.currentUser && !loading) {
      setUser({
        ...data.currentUser,
        complete: Boolean(
          data.currentUser.first && data.currentUser.last && data.currentUser.location?.postal_code,
        ),
      })

      if (data.currentUser.is_new) {
        trackGAEvent({
          action: `event`,
          category: 'Authorization Modal',
          label: `New User Registered`,
          user: data.currentUser,
        })
      }
    }
  }, [data?.currentUser, loading])

  const refetchUser = () => {
    refetch()
  }

  const logout = async () => {
    await fetch(`${process.env.NEXT_PUBLIC_GIS_API}/auth/logout`, {
      method: 'POST',
      body: JSON.stringify({ cookie: user?.cookie }),
      credentials: 'include',
    })

    setUser(null)
  }

  return (
    <AuthContext.Provider value={{ user, isOpen, setIsOpen, refetchUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
