// when modifying this file - also modify in user-portal
import { User } from '../../types'

export type GTagEvent = {
  action: string
  category: string
  label: string
  value?: number
  business_id?: string
  article_id?: string
  campaign_id?: any
  ad_id?: any
  data?: any
  user?: User | null
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export default function trackGAEvent({
  action,
  category,
  label,
  business_id,
  article_id,
  campaign_id,
  ad_id,
  data,
  user,
}: GTagEvent) {
  let eventData = {
    event_category: category,
    event_label: label,
    event_business_id: business_id,
    event_additional_data: data,
    event_user_id: user?.id ? `${user?.id}` : undefined,
    event_user_email: user?.mail ? `${user?.mail}` : undefined,
  }

  if (article_id) {
    eventData['event_article_id'] = article_id
  }

  if (campaign_id) {
    eventData['event_campaign_id'] = campaign_id
  }

  if (ad_id) {
    eventData['event_ad_id'] = ad_id
  }

  if (process.env.NODE_ENV !== 'development' && window.gtag) {
    window.gtag('event', action, eventData)
  } else {
    console.log('event', action, eventData)
  }
}
