import { gql } from '@apollo/client'

export const CURRENT_USER_QUERY = gql`
  query {
    currentUser {
      id
      name
      image
      mail
      provider_user_id
      provider
      cookie
      first
      last
      is_new
      location {
        lid
        name
        street
        additional
        city
        province
        postal_code
        country
        latitude
        longitude
        source
        is_primary
        province_name
        country_name
        distance_deal
        distance_doctor
        distance_event
        distance_group
        distance_poll
        distance_post
        distance_share
        color
      }
    }
  }
`
