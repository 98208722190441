import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import { useAuth } from '@/context/AuthContext'
// import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

const ModalAuth = dynamic(() => import('@components/ModalAuth/ModalAuth'), { ssr: false })
const ResetScreen = dynamic(
  () => import('@components/ModalAuth/components/ResetScreen/ResetScreen'),
  { ssr: false },
)
const AuthScreen = dynamic(() => import('@components/ModalAuth/components/AuthScreen/AuthScreen'), {
  ssr: false,
})

const AuthModal: React.FC = () => {
  const { isOpen, setIsOpen, refetchUser } = useAuth()
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const [showResetForm, setShowResetForm] = useState(false)

  const handleModalAuthClose = () => {
    if (searchParams && searchParams.size > 0) {
      const searchParamsCopy = new URLSearchParams(searchParams.toString())
      searchParamsCopy.delete('withReviewModal')
      searchParamsCopy.delete('authWithKid')

      router.push(`${pathname}?${searchParamsCopy.toString()}`)
    }

    setIsOpen(false)
    setShowResetForm(false)
  }

  const toggleResetForm = () => {
    setShowResetForm(!showResetForm)
  }

  const handleSuccessAuth = (isNewUser) => {
    refetchUser()
    setIsOpen(false)

    if (!searchParams) return

    const searchParamsCopy = new URLSearchParams(searchParams.toString())
    searchParamsCopy.delete('authWithKid')

    let addKidParams: { addKid: 'true' } | null = null
    let redirectTo: string | null = isNewUser
      ? `${process.env.NEXT_PUBLIC_GIS_USER_PORTAL}/kids`
      : null

    if (searchParams.get('authWithKid')) {
      addKidParams = { addKid: 'true' }
    }

    if (searchParams.get('withReviewModal') || searchParams.get('authWithKid')) {
      redirectTo = null

      const newSearchParams = {
        ...Object.fromEntries(searchParamsCopy.entries()),
        ...addKidParams,
      }

      router.push(`${pathname}?${new URLSearchParams(newSearchParams).toString()}`)
    }

    if (isNewUser && redirectTo) {
      window.location.href = redirectTo
    }
  }

  return (
    <>
      {isOpen && (
        <ModalAuth isOpen={isOpen} onClose={handleModalAuthClose} styles={{ zIndex: 1301 }}>
          {showResetForm ? (
            <ResetScreen />
          ) : (
            <AuthScreen onResetClick={toggleResetForm} onSuccess={handleSuccessAuth} />
          )}
        </ModalAuth>
      )}
    </>
  )
}

export default AuthModal
