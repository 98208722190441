import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/node_modules/next/font/google/target.css?{\"path\":\"config/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"fallback\":[\"Verdana\",\"Helvetica\",\"sans-serif\"],\"preload\":true,\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/node_modules/next/font/google/target.css?{\"path\":\"config/fonts.ts\",\"import\":\"Lato\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"weight\":[\"400\",\"700\",\"900\"],\"fallback\":[\"Lucida Grande\",\"Tahoma\",\"Sans-Serif\"],\"preload\":true,\"display\":\"swap\",\"variable\":\"--font-lato\"}],\"variableName\":\"lato\"}");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/public/redesign/assets/icons/social/fb.svg");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/public/redesign/assets/icons/social/inst.svg");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/public/redesign/assets/icons/social/x.svg");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/public/redesign/assets/logo/beakid/beakid.svg");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/components/Layout/components/Footer/components/Socials/Socials.module.scss");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/components/Layout/components/Footer/components/Header/Header.module.scss");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/components/Layout/components/Footer/components/Content/Content.module.scss");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/components/Layout/components/Footer/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/local/www/gis.beakid.com-nextjs/src/components/Layout/components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/components/Layout/components/Main/Main.module.scss");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/components/Layout/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["RootProvider"] */ "/local/www/gis.beakid.com-nextjs/src/providers/RootProvider.tsx");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/styles/index.scss");
