'use client'
import { ViewportContext, TViewportContext } from '@/context/ViewportContext'
// import { UserLocationContext, TUserLocationContext } from '@/context/UserLocationContext'
import { ApolloProvider } from '@apollo/client'
import { AuthProvider } from '@/context/AuthContext'
import client from '../../config/apollo-client'

type TProviders = {
  children: React.ReactNode
} & TViewportContext

export function RootProvider({ children, isMobile }: TProviders) {
  return (
    <ApolloProvider client={client}>
      <ViewportContext.Provider value={{ isMobile }}>
        {/*<UserLocationContext.Provider value={{ ip: userIp }}>*/}
        <AuthProvider>{children}</AuthProvider>
        {/*</UserLocationContext.Provider>*/}
      </ViewportContext.Provider>
    </ApolloProvider>
  )
}
