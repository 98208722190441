import React, { ElementType } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Link from 'next/link'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

export type TListItem = {
  name: string
  Icon: ElementType
  href?: string
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
  rel?: string
  target?: string
  disabled?: boolean
  event_label?: string
  event_category?: string
}

type TDropdown = { onClose: () => void; anchorEl: any; isOpen: boolean; list: TListItem[] }

const paddingMenuItem = '20px'

const StyledMenu = styled(Menu)({
  zIndex: 'var(--zindex-cover-mui)',
  '& .MuiPaper-root': {
    backgroundColor: 'var(--color-background)',
    borderRadius: 'var(--border-radius-dropdown)',
    minWidth: 221,
    boxShadow: 'var(--box-shadow-dropdown)',
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      gap: 20,
      padding: paddingMenuItem,
      position: 'relative',
      '& .MuiListItemIcon-root': {
        minWidth: 'unset',
        width: 16,
        height: 16,
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .MuiListItemText-root': {
        fontSize: 'var(--font-size-description-small)',
        lineHeight: 'var(--line-height-description-small)',
        color: 'var(--color-text-description-small-main)',
      },
      '&:active': {
        backgroundColor: 'var(--color-bg-dropdown--active)',
      },
      '&:hover': {
        backgroundColor: 'var(--color-bg-dropdown--hover)',
      },
      '&:focus': {
        backgroundColor: 'var(--color-bg-dropdown--hover)',
      },
      '&.Mui-disabled': {
        cursor: 'default',
        opacity: 1,

        '& .MuiListItemText-root': {
          color: 'var(--color-text-description-small-main--disabled)',
        },
        '& .MuiListItemIcon-root': {
          '& svg path': {
            stroke: 'var(--color-text-description-small-main--disabled)',
          },
        },
      },
    },
  },
})

const DividerComponent = () => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        height: 1,
        width: `calc(100% - (${paddingMenuItem} * 2))`,
        left: paddingMenuItem,
        backgroundColor: 'var(--color-devider)',
      }}
    />
  )
}

export default function Dropdown({ onClose, anchorEl, isOpen, list }: TDropdown) {
  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {list.map((item, i) => {
        let linkStyles: React.CSSProperties = { textDecoration: 'none' }

        if (item.disabled) {
          linkStyles = { ...linkStyles, pointerEvents: 'none', cursor: 'default' }
        }

        return (
          <Link
            href={item.href || ''}
            passHref
            key={i}
            style={linkStyles}
            onClick={(e) => {
              if (item.onClick) {
                item.onClick(e)

                if (item.event_label && item.event_category) {
                  trackGAEvent({
                    action: 'click',
                    category: item.event_category,
                    label: item.event_label,
                    data: `page_url: ${window.location.href}`,
                  })
                }
              }
            }}
          >
            <MenuItem onClick={onClose} rel={item.rel} disabled={item.disabled} disableRipple>
              <ListItemIcon>
                <item.Icon />
              </ListItemIcon>
              <ListItemText primary={item.name} />
              {i < list.length - 1 && <DividerComponent />}
            </MenuItem>
          </Link>
        )
      })}
    </StyledMenu>
  )
}
