import { ApolloClient, InMemoryCache } from '@apollo/client'

// We set the credentials option below because the server for the app runs on a different port than the GraphQL API.
// Without this option, the session cookie wouldn't be included in the request to the server.
const client = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  credentials: 'include',
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export default client
